<template>
  <div id="wxTaskInfo">
    <BackApprove title="审批详情"></BackApprove>
    <iframe :src="src"></iframe>
  </div>
</template>

<script>
import BackApprove from "@/components/backApprove";

export default {
  components: { BackApprove },
  data() {
    return {
      mobile: "",
      fullName: "",
      spId: "",
      processType: "",
      approveId: "",
    };
  },
  created() {
    this.processType = this.$route.query.processType;
    this.approveId = this.$route.query.approveId;
    this.mobile = this.$route.query.mobile;
    this.fullName = this.$route.query.fullName;
    this.spId = this.$route.query.spId;
  },
  computed: {
    //【转换】跳转路径
    src() {
      const host = process.env.VUE_APP_WXURL;
      const src =
        host +
        "mobile=" +
        this.mobile +
        "&userName=" +
        this.fullName +
        "&processType=" +
        this.processType +
        "&approveId=" +
        this.approveId +
        "&spId=" +
        this.spId;
      
      return src;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#wxTaskInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  iframe {
    height: calc(100% - 46px);
    width: 100%;
    border: 0;
  }
}
</style>