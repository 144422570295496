<template>
  <div id="back">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    >
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    onClickLeft() {
      this.$router.push('/task/taskList');
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/theme";
#back {
  .van-nav-bar {
    background: $theme-color;
  }
  .van-icon,
  .van-nav-bar__text,
  .van-nav-bar__title {
    color: #fff !important;
    font-size: 0.18rem;
  }
}
</style>